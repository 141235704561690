import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Theme,
} from '@mui/material';
import { MapPin } from 'react-feather';

import { PVBlack, PVWhite } from '@pv/common/colors';

import type { Venue } from '../types/models/venue_types';
import { StyledLogoImg } from '../lib/styled/common';
import { Store } from './icons';

/*
 * Types.
 */

interface ProfileHeaderProps {
  venue: Venue;
}

interface StyledBannerImgProps {
  src: string;
  theme: Theme;
}

/*
 * Constants.
 */

const StyledBannerImg = styled(Box)<StyledBannerImgProps>(({ src, theme }) => ({
  flexShrink: 0,
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.09) 54%), url(${src}) lightgray 50% / cover no-repeat`,
  boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset',
  width: '100%',
  height: '460px',
  [theme.breakpoints.down('md')]: {
    height: '260px',
  },
}));

const StyledProfileHeaderAddress = styled('address')({
  fontStyle: 'normal',
});

/*
 * Component.
 */

const VenueTypeBadge = ({ type }: { type: string }) => {
  const theme = useTheme();
  const smMedia = useMediaQuery(theme.breakpoints.down('md'));
  return <Stack direction='row' alignItems='center' gap='8px'>
    <Store size={20} sx={{ color: smMedia ? 'black' : 'white' }} />
    <Typography sx={{
      color: {
        sm: PVBlack,
        md: PVWhite,
      },
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '160%',
    }}>{type}</Typography>
  </Stack>
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({
  venue,
}: ProfileHeaderProps) => {
  const theme = useTheme();
  const venueBannerPath = venue.profileBanner ?? '/default_venue_banner.jpg';
  const logoPath = venue.logo ?? '/default_logo.png';
  const smMedia = useMediaQuery(theme.breakpoints.down('md'));
  const xsMedia = useMediaQuery(theme.breakpoints.down('sm'));

  const venueDisplayAddress = venue.address
    ? `${venue.address.streetAddress1}, ${venue.address.city}, ${venue.address.state}`
    : '';

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'column',
        md: 'row',
      }}
      justifyContent={{
        xs: 'flex-end',
        sm: 'flex-end',
        md: 'center',
      }}
      alignItems={{
        md: 'flex-end',
      }}
      marginBottom={{
        xs: '-120px',
        sm: '-120px',
        md: '0px',
      }}
    >
      <StyledBannerImg theme={theme} src={venueBannerPath} />
      <Stack
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
        }}
        gap="40px"
        alignItems={{
          xs: 'flex-start',
          sm: 'flex-start',
          md: 'center',
        }}
        width={{
          md: '100%',
          lg: '1200px',
        }}
        padding={{
          xs: '0px 32px',
          sm: '0px 32px',
          md: '0px 32px 40px',
          lg: '0px 0px 40px',
        }}
        position={{
          xs: 'relative',
          sm: 'relative',
          md: 'absolute',
        }}
        bottom={smMedia ? '120px' : undefined}
        sx={{
          zIndex: '1',
        }}
      >
        <Box
          sx={{
            background: 'white',
            borderRadius: '50%',
            height: '144px',
            width: '144px',
            flexShrink: 0,
            overflow: 'hidden',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
              height: '100px',
              width: '100px',
            },
          }}
        >
          <StyledLogoImg alt={`${venue.name} logo`} src={logoPath} />
        </Box>
        <Stack direction="column" gap="12px">
          <Stack direction="column">
            <Typography
              variant="h1"
              sx={{
                color: {
                  sm: PVBlack,
                  md: PVWhite,
                },
                fontFamily: 'Gazpacho',
                fontSize: '56px',
                fontWeight: '700',
                lineHeight: '60px',
                [theme.breakpoints.down('md')]: {
                  fontSize: '36px',
                },
              }}
            >
              {venue.name}
            </Typography>
            {venue.address && (
              <StyledProfileHeaderAddress>
                <Typography
                  sx={{
                    color: {
                      sm: PVBlack,
                      md: PVWhite,
                    },
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '123.5%',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '20px',
                    },
                  }}
                >
                  {venue.address.city}
                </Typography>
              </StyledProfileHeaderAddress>
            )}
          </Stack>
          {venue.address && (
            <Stack
              direction={xsMedia ? 'column' : 'row'}
              alignItems={xsMedia ? 'flex-start' : 'center'}
              gap="12px"
              fontStyle="normal"
            >
              <Stack direction='row' gap='8px' alignItems='center'>
                <MapPin color={smMedia ? 'black' : 'white'} size={20} />
                <StyledProfileHeaderAddress>
                  <Typography
                    sx={{
                      color: {
                        sm: PVBlack,
                        md: PVWhite,
                      },
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '160%',
                    }}
                  >
                    {venueDisplayAddress}
                  </Typography>
                </StyledProfileHeaderAddress>
              </Stack>
              {!xsMedia && venue.type && <Typography sx={{
                color: {
                  sm: PVBlack,
                  md: PVWhite,
                },
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '160%',
              }}>{'\u2022'}</Typography>}
              {venue.type && <VenueTypeBadge type={venue.type} />}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
